import React, { Component } from "react";
import { Grid } from "../ui";
import { firebase } from "../firebase";
import { Post } from "../components";

export default class ManageNews extends Component {
  constructor() {
    super();
    this.state = {
      news: []
    };
    this.fetchData = this.fetchData.bind(this);
    this.deletePost = this.deletePost.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let { docs } = await firebase
      .firestore()
      .collection("news")
      .get();
    docs = docs.map(doc => ({ id: doc.id, ...doc.data() }));
    this.setState({ news: docs });
  }

  async deletePost(id) {
    await firebase
      .firestore()
      .collection("news")
      .doc(id)
      .delete();
    this.setState({ news: this.state.news.filter(i => i.id !== id) });
  }

  render() {
    return (
      <Grid
        gutter={20}
        cols={2}
        items={this.state.news}
        render={post => (
          <Post
            post={post}
            onDelete={this.deletePost}
            afterSubmit={this.fetchData}
          />
        )}
      />
    );
  }
}
