import React, {Component} from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./NewsTimeline.css";
import {Post} from "../components";
import {firebase} from "../firebase";
import styled from "styled-components";
import {Container} from "../ui";

const TimelinePost = styled(Post)`
  box-shadow: none !important;
  margin: -16px;
  margin-bottom: 0;
  /* background: #2B364F; */
  color: #c1c1c1 !important;
`;

export default class NewsTimeline extends Component {
    constructor() {
        super();
        this.state = {
            news: []
        };
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let {docs} = await firebase
            .firestore()
            .collection("news")
            .orderBy("createdAt", "desc")
            .get();
        docs = docs.map(doc => ({id: doc.id, ...doc.data()}));

        this.setState({news: docs});
    }

    render() {

        return (
            <Container>
                {this.state.news.length > 0 &&
                <VerticalTimeline layout="1-column">
                    {this.state.news.map(post => (
                        <VerticalTimelineElement
                            position="right"
                            date={post.createdAt.toDate().toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "long",
                            })}
                            key={post.id}
                        >
                            <TimelinePost readonly post={post}/>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
                }
            </Container>
        );
    }
}
