import React, { Component } from "react";
import { Flex } from "../ui";
import styled from "styled-components";
import { theme } from "../theme";
import { firebase } from "../firebase";

const StatsContainer = styled(Flex)`
  padding: 2rem;
`;

const StatsNumber = styled.h1`
  font-size: 5rem;
  color: white;
  margin-bottom: 0;
  @media screen and (max-width: 576px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

const StatsTitle = styled.h2`
  color: ${theme.colors.greyLighter} !important;
`;

export default class AdminDashboard extends Component {
  constructor() {
    super();
    this.state = {
      totalInvested: 0,
      totalEarned: 0,
      listener: null
    };
  }

  async componentDidMount() {
    const listener = await firebase
      .firestore()
      .collection("stats")
      .doc("main-stats")
      .onSnapshot(stats => {
        this.setState(stats.data());
      });
    this.setState({ listener });
  }

  componentWillUnmount() {
    this.setState({ listener: null });
  }

  render() {
    return (
      <Flex wrap>
        <StatsContainer column itemsCenter contentCenter>
          <StatsNumber>€{this.state.totalInvested}</StatsNumber>
          <StatsTitle>Total Invested</StatsTitle>
        </StatsContainer>
      </Flex>
    );
  }
}
