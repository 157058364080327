import React, { Component } from "react";
import styled from "styled-components";
import { Button, Modal, Input, Flex } from "../ui";
import { firebase } from "../firebase";

const PostContainer = styled.div`
  box-sizing: border-box;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  text-align: center;
  padding: 1rem;
  text-align: left;
  padding-right: 2rem;
  @media screen and (max-width: 768px) {
    padding-right: 1.5rem;
  }
`;

const PostTitle = styled.h2`
  font-size: 1.75rem !important;
  margin-bottom: 0.75rem;
  color: #707070 !important;
  @media screen and (max-width: 768px) {
    font-size: 1.25rem !important;
  }
`;

const PostImage = styled.div`
  height: 300px;
  margin: 1.5rem 0;
 /* @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }*/
  margin-bottom: 0;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

class Inputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: undefined,
      body: undefined,
      filePath: "",
      fileName: undefined,
      file: null,
      createdAt: new Date()
    };
    this.onInput = this.onInput.bind(this);
    this.onFileInput = this.onFileInput.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  onFileInput({ target }) {
    this.setState({
      file: target.files[0],
      filePath: target.value,
      fileName: target.files[0].name
    });
  }

  onInput({ target }) {
    this.setState({ [target.name]: target.value });
  }

  async submitData(e) {
    e.preventDefault();
    const ref = firebase.storage().ref();
    const fileRef = ref.child("news/" + this.state.fileName);
    let payload = {
      title: this.state.title,
      body: this.state.body,
      //Setting date here cause convert it to string instead of Date, because of
      //JSON.stringify below
      //createdAt: new Date(this.state.createdAt)
    };

    try {
      if (this.state.file) {
        const snapshot = await fileRef.put(this.state.file);
        const downloadPath = await snapshot.ref.getDownloadURL();
        payload.image = downloadPath;
      }
      payload = JSON.parse(JSON.stringify(payload));

      payload.createdAt = new Date(this.state.createdAt);
      await firebase
        .firestore()
        .collection("news")
        .doc(this.props.post.id)
        .update(payload);

      this.props.afterSubmit();
      this.setState({
        title: undefined,
        body: undefined,
        filePath: undefined,
        fileName: undefined,
        file: null,
        createdAt: ''
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { title, filePath, createdAt, body } = this.state;
    return (
      <>
        <form onSubmit={this.submitData}>
          <Flex column>
            <Input
              type="text"
              placeholder={this.props.post.title}
              value={title}
              onChange={this.onInput}
              name="title"
            />
            <Input type="file" value={filePath} onChange={this.onFileInput} />
            <Input
              type="date"
              value={createdAt}
              onChange={this.onInput}
              name="createdAt"
            />
            <Input
              as="textarea"
              placeholder={this.props.post.body}
              rows={20}
              value={body}
              onChange={this.onInput}
              name="body"
            />
            <Button blue>Submit</Button>
            <Button
              orange
              onClick={e => {
                e.preventDefault();
                this.props.onCancel();
              }}
            >
              Cancel
            </Button>
          </Flex>
        </form>
      </>
    );
  }
}

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    };
  }
  render() {
    const { readonly, post, className, onDelete } = this.props;

    if (readonly) {
      return (
        <PostContainer className={className}>
          <PostTitle>{post.title}</PostTitle>
          <p>{post.body}</p>
          {post.image ? <PostImage src={post.image} /> : null}
        </PostContainer>
      );
    } else {
      return (
        <>
          <Modal
            render={
              <Inputs
                post={post}
                onCancel={() => this.setState({ modalVisible: false })}
                afterSubmit={this.props.afterSubmit}
              />
            }
            visible={this.state.modalVisible}
          />
          <PostContainer className={className}>
            <PostTitle>{post.title}</PostTitle>
            {post.image ? <PostImage src={post.image} /> : null}
            <p>{post.body}</p>
            <Button orange onClick={() => onDelete(post.id)}>
              Delete
            </Button>
            <Button blue onClick={() => this.setState({ modalVisible: true })}>
              Edit
            </Button>
          </PostContainer>
        </>
      );
    }
  }
}

export default Post;
