import styled from "styled-components";

const Background = styled.div`
  /* background-color: #26415E; */
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100.5vh;
  @media (max-width: 767px) {
    background-size: auto;
    background-repeat: repeat-y;
  }
`;

export default Background;
