import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDnfh-siPzpLNkq-PdYbUenSk7RIGXRkn4",
  authDomain: "pixtunes-ebb9b.firebaseapp.com",
  databaseURL: "https://pixtunes-ebb9b.firebaseio.com",
  projectId: "pixtunes-ebb9b",
  storageBucket: "pixtunes-ebb9b.appspot.com",
  messagingSenderId: "742020295662"
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export { firebase };
