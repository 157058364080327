import React from "react";
import styled from "styled-components";
import { Flex } from ".";

const GridContainer = styled(Flex)`
  margin: -${({ margin }) => margin}px;
  width: 100%;
  @media screen and (max-width: 576px) {
    justify-content: center;
    margin: 0 !important;
    width: 100vw !important;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    margin: 0 !important;
    width: 100vw !important;
  }
`;

const ItemContainer = styled.div`
  margin: ${({ margin }) => margin}px;
  flex-basis: ${({ cols, margin }) => `calc(100% / ${cols} - ${margin * 2}px)`};
  @media screen and (max-width: 576px) {
    flex-basis: 100%;
  }
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`;

const Grid = ({ gutter, items, render, cols }) => {
  return (
    <GridContainer wrap margin={gutter}>
      {items.map(item => (
        <ItemContainer
          margin={gutter}
          cols={cols}
          key={item.id || Math.random()}
        >
          {render(item)}
        </ItemContainer>
      ))}
    </GridContainer>
  );
};

export default Grid;
