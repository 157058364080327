import styled from "styled-components";

const BackgroundBefore = styled.div`
  &:before {
    background-image: url(${({src}) => src});
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    //height: 100%;
    //transition: height 999999s; //tricky solution which may cause bugs in a future
    z-index: -99;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
`;

export default BackgroundBefore;
