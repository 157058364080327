import styled from "styled-components";

const RightContainer = styled.main`
  width: calc(100% / 4 * 3);
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export default RightContainer;
