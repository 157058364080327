import React, { Component } from "react";
import { Flex, Input, Button, Title } from "../ui";
import { firebase } from "../firebase";

export default class AddNews extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      body: "",
      filePath: "",
      fileName: "",
      file: null,
      createdAt: new Date()
    };
    this.onInput = this.onInput.bind(this);
    this.onFileInput = this.onFileInput.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  onFileInput({ target }) {
    this.setState({
      file: target.files[0],
      filePath: target.value,
      fileName: target.files[0].name
    });
  }

  onInput({ target }) {
    this.setState({ [target.name]: target.value });
  }

  async submitData(e) {
    e.preventDefault();
    const ref = firebase.storage().ref();
    const fileRef = ref.child("news/" + this.state.fileName);
    let payload = {
      title: this.state.title,
      body: this.state.body,
      createdAt: new Date(this.state.createdAt)
    };

    try {
      if (this.state.file) {
        const snapshot = await fileRef.put(this.state.file);
        const downloadPath = await snapshot.ref.getDownloadURL();
        payload.image = downloadPath;
      }
      await firebase
        .firestore()
        .collection("news")
        .add(payload);
      this.setState({
        title: "",
        body: "",
        filePath: "",
        fileName: "",
        file: null,
        createdAt: ""
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { title, body, filePath, createdAt } = this.state;
    return (
      <>
        <Title style={{ color: "white" }}>Add news</Title>
        <Flex>
          <form onSubmit={this.submitData}>
            <Flex column>
              <Input
                type="text"
                placeholder="Title"
                value={title}
                onChange={this.onInput}
                name="title"
              />
              <Input type="file" value={filePath} onChange={this.onFileInput} />
              <Input
                type="date"
                value={createdAt}
                onChange={this.onInput}
                name="createdAt"
              />
              <Input
                as="textarea"
                placeholder="News text"
                rows={20}
                value={body}
                onChange={this.onInput}
                name="body"
              />
              <Button blue>Submit</Button>
            </Flex>
          </form>
        </Flex>
      </>
    );
  }
}
