import React from "react";
import styled from "styled-components";
import { Flex } from ".";

const ModalContainer = styled.div`
  display: ${({ visible }) => (visible ? "block" : "none")};
  padding: 2rem;
  max-width: 600px;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  background: white;
`;

const Modal = ({ render, visible, className, onCancel }) => {
  return (
    <ModalContainer visible={visible} className={className}>
      <Flex column itemsCenter contentCenter>
        {render}
      </Flex>
    </ModalContainer>
  );
};

export default Modal;
