import React, {Component} from "react";
import {RightContainer, BackgroundBefore, Flex} from "../ui";
import {firebase} from "../firebase";
import {Sidebar, Header} from "../components";
import {Route} from "react-router-dom";
import {Account, NewsTimeline, AllInvestors, Welcome} from "../pages";
import * as background from "../assets/background_image_v5.jpg";

export default class Investors extends Component {
    constructor() {
        super();
        this.state = {
            sidebarOpen: false,
            hideMenuIcon: false,
            links: [
                {
                    text: null,
                    path: "/account",
                    component: Account
                },
                {
                    text: "Account",
                    path: "/welcome",
                    component: Welcome
                },
                {
                    text: "News",
                    path: "/news",
                    component: NewsTimeline
                },
                {
                    text: "Investors",
                    path: "/all",
                    component: AllInvestors
                }
            ]
        };
        this.logout = this.logout.bind(this);
        this.openSidebar = this.openSidebar.bind(this);
    }

    componentDidMount() {
        if (window.innerWidth > 768) {
            setTimeout(
                () =>
                    this.setState({
                        sidebarOpen: true
                    }),
                500
            );
        }

        window.addEventListener('scroll', this.handleScroll);

        // this.props.history.push("/admin/dashboard");
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);

    }


    handleScroll = () => {
        //this.setState({hideMenuIcon: !!(window.pageYOffset >= 100)});

        let {hideMenuIcon} = this.state;
        //added more check to avoid waste render
        if (window.pageYOffset >= 100 && !hideMenuIcon) {
            this.setState({hideMenuIcon: true});
        } else if (window.pageYOffset <= 100 && hideMenuIcon) {
            this.setState({hideMenuIcon: false});
        }
    };

    openSidebar() {
        if (window.innerWidth <= 768) {
            this.setState({
                sidebarOpen: !this.state.sidebarOpen
            });
        }
    }

    async logout() {
        await firebase.auth().signOut();
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("uid");
        this.props.history.push("/login");
    }

    render() {

        return (
            <BackgroundBefore src={background}>
                <button
                    className={`${
                        this.state.sidebarOpen
                            ? "menu-icon burger isOpen"
                            : "menu-icon burger"
                        }
                        ${
                        this.state.hideMenuIcon ? 'menu-hidden' : ''
                        }`}
                    onClick={this.openSidebar}
                >
                    <span/>
                </button>
                <Sidebar
                    isOpen={this.state.sidebarOpen}
                    links={this.state.links}
                    match={this.props.match}
                    onLogout={this.logout}
                    openSidebar={this.openSidebar}
                    right={window.innerWidth < 576}
                    noOverlay={window.innerWidth > 576}
                />
                <Flex contentEnd>
                    <RightContainer>
                        <Header/>
                        {this.state.links.map(({text, path, component}) => (
                            <Route
                                key={path}
                                path={`${this.props.match.path}${path}`}
                                component={component}
                            />
                        ))}
                    </RightContainer>
                </Flex>
            </BackgroundBefore>
        );
    }
}
