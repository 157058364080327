import React, { Component } from "react";
import styled from "styled-components";
import { Title, Text, Button, Container } from "../ui";
import { Link } from "react-router-dom";
import { firebase } from "../firebase";
import { theme } from "../theme";

const CardContainer = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  padding-top: 2rem;
  margin-top: 50px;
  color: ${theme.colors.greyDarker} !important;
  margin-right: 50px;
  position: relative;
  color: #696969 !important;

  .container {
    width: 85% !important;
    margin: 0 auto 1rem auto;
  }

  .text {
    min-height: 160px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    margin-top: 50px;
    width: 85%;
  }
`;

const StrongTitle = styled(Title)`
  font-weight: 500;
  color: #707070 !important;
`;

const ContentContainer = styled.div`
  width: 55%;
  @media screen and (max-width: 992px) {
    width: auto;
  }
`;

const ContactTrack = styled.div`
  background-color: #151D32;
  //line-height: 1.6rem;
  padding: 0.9rem 0;
  margin-bottom: 3rem;
  font-size: 16px;
  color: #B4B4B4;
  font-family: GorditaLight;
  strong {
    color: #B4B4B4;
    font-family: GorditaMedium;
  }
`;

const AddBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-size: 17px;
  margin-top: 2rem;

  @media screen and (max-width: 978px) {
    flex-direction: column;

    & > div {
      width: 100% !important;
      margin-top: 1rem;
    }
  }

  & > div {
    width: 30%;
    font-family: GorditaRegular;

    & > span {
      margin-bottom: 1rem;
      font-family: GorditaMedium;
    }
  }
`;

export default class Welcome extends Component {
  constructor() {
    super();

    window.scrollTo(0, 0);

    this.state = {
      name: "",
      contractSigned: false
    };
  }

  async componentDidMount() {
    try {
      let name = window.localStorage.getItem("displayName");
      let uid = window.localStorage.getItem("uid");

      console.log(name, uid);

      if (!name) {
        let currentUser = await firebase.auth().currentUser;
        name = currentUser.displayName;
        window.localStorage.setItem("displayName", currentUser.displayName);
      }
      if (!uid) {
        let currentUser = await firebase.auth().currentUser;
        uid = currentUser.uid;
        window.localStorage.setItem("uid", currentUser.uid);
      }
      const doc = await firebase
        .firestore()
        .collection("investors")
        .doc(uid)
        .get();
      const contractSigned = doc.data().contractSigned;

      let firstName = name.replace(/ .*/, "");
      this.setState({ name: firstName, contractSigned });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    if (!this.state.name) {
      return null;
    }

    return (
      <CardContainer>
        <div className="container">
          <ContentContainer>
            <StrongTitle>
              {this.state.contractSigned ? (
                <span>
                  Hello {this.state.name || "Loading..."}. Thanks a lot for your
                  trust and support!
                </span>
              ) : (
                <span>
                  Hello {this.state.name || "Loading..."}. Thanks a lot for your
                  interest!
                </span>
              )}
            </StrongTitle>
            <Text
              style={{
                fontSize: "18px",
                lineHeight: "22px",
                fontFamily: "GorditaRegular"
              }}
            >
              {!this.state.contractSigned ? (
                <span>
                  As we have not agreed on a contract yet, on the following page
                  you can test the relations between your potential investment
                  and your theoretical future earnings. If the company is
                  successful you will participate in that success from the
                  beginning.
                </span>
              ) : (
                <span>
                  What a brave and clever move to support Pixtunes on such an
                  early stage. This really means a lot to us and we will always
                  be grateful for that. On the following page, you can test the
                  relations between your investment and your theoretical future
                  earnings within the terms of our contract. If the company is
                  successful you will participate in that success from the
                  beginning.
                </span>
              )}
            </Text>
            <Button
              blue
              style={{
                marginLeft: "-1px",
                display: "inline-block",
                textDecoration: "none",
                textAlign: "center",
                color: "white"
              }}
              as={Link}
              fullWidth={window.innerWidth < 768}
              to="/investors/account"
            >
              {!this.state.contractSigned ? "Let's go" : "Enter"}
            </Button>
          </ContentContainer>

          <AddBlock>
            <div>
              <span>Why we work together with private investors?</span>
              <p>
                We were lucky to bootstrap our startup with our own money and
                the help of private investors. Within this Freedom, we realized
                our vision, developed our prototypes and strategy without
                chains.
              </p>
            </div>
            <div>
              <span>How do we spend the capital that you provide?</span>
              <p>
                As we are in Pre Seed Status, we use most of our financial
                resources for the creation of pioneering and one of a kind
                Technologie in order to convince future Investors to provide
                Seed Funding.
              </p>
            </div>
            <div>
              <span>What you can expect from us in the future? </span>
              <p>
                Making dreams come true is one of the greatest fullfilments in
                life. Because of your outstanding move, we can do that every
                day. Therefore we will happily share our success in the future
                with you.
              </p>
            </div>
          </AddBlock>
        </div>
        <ContactTrack>
          <Container>
            <strong>Maestron GmbH</strong>
            <strong>&nbsp;&nbsp;·&nbsp;&nbsp;</strong>
            Lychener Str. 68
            <strong>&nbsp;&nbsp;·&nbsp;&nbsp;</strong>
            10437 - Berlin
            <strong>&nbsp;&nbsp;·&nbsp;&nbsp;</strong>
            mobil: +4915140428827
            <strong>&nbsp;&nbsp;·&nbsp;&nbsp;</strong>
            mail: info@maestron.de
          </Container>
        </ContactTrack>
      </CardContainer>
    );
  }
}
