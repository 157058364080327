import PropTypes from "prop-types";
import React from "react";
import {slide as Menu} from "react-burger-menu";
import {NavLink as Link} from "react-router-dom";
import styled from "styled-components";
import {theme} from "../theme";
import {Button} from "../ui";
import "./sidebar.css";

const MenuLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: inherit;
  padding: 3rem;
  position: relative;
  outline: none;
  font-size: 36px;
  font-family: "GorditaRegular";

  @media screen and (max-width: 768px) {
    padding: 3rem 0px;

    &:hover {
      &::before {
        width: 1.2rem !important;
        height: 3px;
        left: -1.6rem;
      }
    }

    &.active {
      &::before {
        width: 1.2rem !important;
        height: 3px;
        left: -1.6rem;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 900px) {
    font-size: 26px;
  }

  &::before {
    content: "";
    position: absolute;
    left: -1em;
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    width: 0;
    background-color: ${theme.colors.blueLighter};
    transition: width ease 0.3s;
  }
  &:hover {
    &::before {
      width: 4rem;
    }
  }

  &.active {
    &::before {
      width: 4rem;
    }
  }
`;

const SidebarButton = styled(Button)`
  margin-left: 42.5px;
  padding: 1.25rem 1.5rem;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const Sidebar = props => {
    return (
        <Menu
            {...props}
            width={window.innerWidth}
            customBurgerIcon={false}
            customCrossIcon={false}
            disableCloseOnEsc
        >
            {props.links.map(({text, path}) => {
                if (text) {
                    return (
                        <MenuLink
                            to={`${props.match.path}${path}`}
                            key={path}
                            activeStyle={{
                                color: theme.colors.blueLighter
                            }}
                            onClick={props.openSidebar}
                            activeClassName={"active"}
                        >
                            {text}
                        </MenuLink>
                    );
                } else {
                    return null;
                }
            })}
            <SidebarButton dark onClick={props.onLogout}>
                Log Out
            </SidebarButton>
        </Menu>
    );
};

Sidebar.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            path: PropTypes.string.isRequired,
            openSidebar: PropTypes.func.isRequired
        })
    )
};

Sidebar.defaultProps = {
    links: [
        {text: "Example 1", path: "/example1"},
        {text: "Example 2", path: "/example2"},
        {text: "Example 3", path: "/example3"}
    ]
};

export default Sidebar;
