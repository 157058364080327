import styled from "styled-components";

const pickFlex = props => {
  if (props.itemsCenter || props.contentCenter) {
    return "center";
  }
  if (props.itemsStart || props.contentStart) {
    return "flex-start";
  }
  if (props.itemsEnd || props.contentEnd) {
    return "flex-end";
  }
  if (props.itemsBetween || props.contentBetween) {
    return "space-between";
  }
  if (props.itemsAround || props.contentAround) {
    return "space-around";
  }
  if (props.itemsStrech || props.contentStrech) {
    return "stretch";
  }
};

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  height: ${({ fillHeight }) => (fillHeight ? "100%" : "auto")};
  align-items: ${props => pickFlex(props)};
  justify-content: ${props => pickFlex(props)};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "no-wrap")};
  margin: ${({ gutter }) => "-" + gutter + "px"};
  & > * {
    margin: ${({ gutter }) => gutter + "px"};
  }
`;

export default Flex;
