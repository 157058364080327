import React from "react";
import styled from "styled-components";
import { theme } from "../theme";
const CardContainer = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 2rem 0;
  margin-top: 50px;
  /* margin-left: -6rem; */
  color: ${theme.colors.greyDarker} !important;
  .container {
    width: 85% !important;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const Card = props => {
  let { children, className } = props;
  return (
    <CardContainer className={className}>
      <div className="container">{children}</div>
    </CardContainer>
  );
};

export default Card;
