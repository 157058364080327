import React from "react";
import styled from "styled-components";
import { theme } from "../theme";
import InlineSVG from "svg-inline-react";
import { logo } from "../assets";
import { Container } from "../ui";
import * as background from "../assets/header_image_v4.jpg";

const HeaderContainer = styled.div`
  background-image: ${`url(${background})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: ${theme.colors.greyLighter};
  border-bottom: 7px solid #89b7ff;
  width: 100%;
  padding: 2.5rem 0;
  margin: 50px 0 20px 0;
  color: #fff !important;
  @media screen and (max-width: 768px) {
  background-position: left;
    margin-top: 0;
    text-align: left;
    padding: 2rem 0;
  }
  position: relative;
  :after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #89b7ff;
    bottom: 0;
  }
`;

const HeaderSubtitle = styled.h2`
  font-weight: 100;
  text-transform: capitalize;
  margin: 0;
  font-size: 26px;
  font-family: GorditaLight;
  white-space: nowrap;
  margin-left: -2px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

const SVG = styled(InlineSVG)`
  svg {
    width: 18rem;
    height: auto;
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
      width: 13rem;
    }
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Container>
        <SVG src={logo} />
        <HeaderSubtitle>Pre seed private investors</HeaderSubtitle>
      </Container>
    </HeaderContainer>
  );
};

export default Header;
