import React, {Component} from "react";
import {firebase} from "../firebase";
import {Header, Sidebar} from "../components";
import {Route} from "react-router-dom";
import * as background from "../assets/background_image_v5.jpg";

import {
    AddInvestor,
    ManageInvestors,
    AddNews,
    ManageNews,
    AdminDashboard
} from "../pages";
import {RightContainer, Background, Flex} from "../ui";

export default class Admin extends Component {
    constructor() {
        super();
        this.state = {
            sidebarOpen: false,
            links: [
                {
                    text: "Dashboard",
                    path: "/dashboard",
                    component: AdminDashboard
                },
                {
                    text: "Add Investor",
                    path: "/add-investor",
                    component: AddInvestor
                },
                {
                    text: "Manage Investors",
                    path: "/manage-investors",
                    component: ManageInvestors
                },
                {text: "Add News", path: "/add-news", component: AddNews},
                {text: "Manage News", path: "/manage-news", component: ManageNews}
            ]
        };
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        if (window.innerWidth > 1165) {
            setTimeout(() => this.setState({sidebarOpen: true}), 500);
        }
        this.props.history.push("/admin/dashboard");
    }

    async logout() {
        await firebase.auth().signOut();
        this.props.history.push("/login");
    }

    render() {
        return (
            <Background src={background}>
                <Sidebar
                    isOpen={this.state.sidebarOpen}
                    links={this.state.links}
                    match={this.props.match}
                    onLogout={this.logout}
                    right={window.innerWidth < 576}
                    noOverlay={window.innerWidth > 576}
                />
                <Flex contentEnd>
                    <RightContainer>
                        <Header/>
                        {this.state.links.map(({text, path, component}) => (
                            <Route
                                key={path}
                                path={`${this.props.match.path}${path}`}
                                component={component}
                            />
                        ))}
                    </RightContainer>
                </Flex>
            </Background>
        );
    }
}
