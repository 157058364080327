import React from "react";
import { Button } from ".";

const Checkbox = ({ value, onChange }) => {
  return (
    <Button
      muted={!value}
      green={value}
      onClick={e => {
        e.preventDefault();
        onChange();
      }}
    >
      {value ? "Contract signed" : "Contract not signed"}
    </Button>
  );
};

export default Checkbox;
