import styled from "styled-components";
import { theme } from "../theme";

function determineColor(props) {
  if (props.muted) {
    return theme.colors.greyDarker;
  }
  if (props.blue) {
    return theme.colors.blue;
  }
  if (props.green) {
    return theme.colors.green;
  }
  if (props.orange) {
    return theme.colors.orange;
  }
  if (props.dark) {
    return theme.colors.greyDarker;
  }
}

const Button = styled.button`
  padding: 1.25em 3em;
  border-radius: 8px;
  box-sizing: border-box;
  font-weight: bold;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  outline: none;
  border: none;
  background-color: ${props => determineColor(props)};
  color: white;
  margin: 0.5rem;
  margin-top: ${({ mt }) => (mt ? "3rem" : "0.5rem")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  cursor: pointer;
`;

export default Button;
