import React, { Component } from "react";
import { Grid, Container } from "../ui";
import { InvestorCard } from "../components";
import { firebase } from "../firebase";
import styled from "styled-components";
import { Flex } from "../ui";
import accounting from "accounting";

const StatsContainer = styled.div`
  margin-bottom: 3rem;
`;

const StatsNumber = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 0;
  font-family: "GorditaRegular";
  font-weight: 100 !important;
  @media screen and (max-width: 576px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

const StatsTitle = styled.h2`
  color: white !important;
  font-family: GorditaMedium;
  font-size: 2rem;
  margin-top: 65px !important;
`;

export default class AllInvestors extends Component {
  constructor() {
    super();
    this.state = {
      investors: [],
      totalInvested: 0,
      listener: null
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.setState({ listener: null });
  }

  async fetchData() {
    let { docs } = await firebase
      .firestore()
      .collection("investors")
      .where("contractSigned", "==", true)
      .get();
    let totalInvested = 0;
    docs = docs.map(doc => {
      totalInvested += doc.data().invested;
      return {id: doc.id, ...doc.data()};
    });
    // const listener = await firebase
    //   .firestore()
    //   .collection("stats")
    //   .doc("main-stats")
    //   .onSnapshot(stats => {
    //     this.setState(stats.data());
    //   });
    this.setState({
      investors: docs,
      totalInvested: totalInvested
    });
    // this.setState({ listener });
  }

  formatMoney(number) {
    if (number > 0) {
      return accounting.formatMoney(number, "€ ", 0, ".");
    } else {
      return 0;
    }
  }

  render() {
    console.log(this.state.investors)
    return (
      <Container>
        <StatsContainer column>
          <StatsTitle>
            Here you can see how much capital was raised from private investors
            in total until now.
          </StatsTitle>
          <StatsNumber>
            {this.formatMoney(this.state.totalInvested)}
          </StatsNumber>
        </StatsContainer>
        <Flex>
          <Grid
            gutter={20}
            cols={window.innerWidth < 768 ? 1 : 3}
            items={this.state.investors}
            render={investor => <InvestorCard investor={investor} readonly />}
          />
        </Flex>
      </Container>
    );
  }
}
