//#region imports
import React, { Component } from "react";
import { Flex, Container, Button, Input, Background } from "../ui";
import styled from "styled-components";
import { theme } from "../theme";
import InlineSVG from "svg-inline-react";
import { logo } from "../assets";
import * as background from "../assets/background_image_v5.jpg";
import * as loginBackground from "../assets/login_image_square.jpg";
import * as lock from "../assets/icons/lock.png";
import * as user from "../assets/icons/user.png";
import { firebase } from "../firebase";
//#endregion

//#region ui
const LoginForm = styled.form`
  width: 400px;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
   border-radius: 2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-image: ${`url(${loginBackground})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  //background-attachment: fixed;
  color: white;
  text-align: center;
  box-sizing: border-box;
  @media screen and (max-width: 576px) {
    padding: 1rem;
    width: 100%;
  }

  input {
    text-align: center;
    color: #666666;
    margin-left: 0;
    margin-right: 0;
  }
`;

const LoginText = styled.p`
  font-size: 19px;
  font-family: GorditaRegular;
  @media screen and (max-width: 576px) {
    font-size: 15px;
  }
`;

const HGroup = styled.div`
  margin: 2rem 0;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin: 1rem 0;
  }
`;

const SVG = styled(InlineSVG)`
  svg {
    max-width: 70%;
    height: auto;
  }
`;

const LoginContainer = styled(Container)`
  box-sizing: border-box;
  @media screen and (min-width: 1200px) {
    margin: 0 auto;
  }
`;

const Line = styled.div`
  background: -moz-linear-gradient(to right, #fff0 0%, #fff 50%, #fff0 100%);
  background: -webkit-gradient(to right, #fff0 0%, #fff 50%, #fff0 100%);
  background: -webkit-linear-gradient(to right, #fff0 0%, #fff 50%, #fff0 100%);
  background: -o-linear-gradient(to right, #fff0 0%, #fff 50%, #fff0 100%);
  background: -ms-linear-gradient(to right, #fff0 0%, #fff 50%, #fff0 100%);
  background: linear-gradient(to right, #fff0 0%, #fff 50%, #fff0 100%);
  width: 100%;
  height: 2px;
`;

const LoginButton = styled(Button)`
  padding: 1.25em 3em;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  outline: none;
  border: none;
  color: #fff !important;
  font-family: GorditaRegular;

  background-color: #1b9ce2;
  font-size: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  height: 65px;

  &:hover {
    background-color: ${theme.colors.blueLighter};
  }
`;

//#endregion

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      error: ""
    };
    this.login = this.login.bind(this);
  }

  async login(e) {
    e.preventDefault();
    const { email, password } = this.state;
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("uid");

    try {
      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      window.localStorage.setItem("displayName", user.displayName);
      window.localStorage.setItem("uid", user.uid);

      const { claims } = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);

      if (claims.admin) {
        this.props.history.push("/admin/dashboard");
      } else {
        this.props.history.push("/investors/welcome");
      }
    } catch (error) {
      const { message } = error;
      this.setState({ error: message });
    }
  }
  render() {
    return (
      <Background src={background}>
        <LoginContainer screen>
          <Flex fillHeight column itemsCenter contentCenter>
            <LoginForm onSubmit={this.login}>
              <HGroup>
                <SVG src={logo} />
              </HGroup>
              <Line />
              <LoginText>
                <span style={{ whiteSpace: "nowrap" }}>
                  Please login with your personal
                </span>{" "}
                <br /> <span>Username and Password</span>
              </LoginText>
              <Flex fillHeight column>
                <div>
                  <Input
                    icon={user}
                    type="text"
                    placeholder="email"
                    value={this.state.email}
                    onChange={({ target }) =>
                      this.setState({ email: target.value })
                    }
                  />
                  <Input
                    icon={lock}
                    type="password"
                    placeholder="password"
                    value={this.state.password}
                    onChange={({ target }) =>
                      this.setState({ password: target.value })
                    }
                  />
                </div>
                <LoginButton blue={!this.state.error} orange={this.state.error}>
                  {this.state.error ? "Try again" : "Log in"}
                </LoginButton>
              </Flex>
            </LoginForm>
          </Flex>
        </LoginContainer>
      </Background>
    );
  }
}
