import React, { Component } from "react";
import { Flex, Input, Checkbox, Button, Title } from "../ui";
import { firebase } from "../firebase";

export default class AddInvestor extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      invested: "",
      earned: "",
      percentageRanges: {
        first: "",
        second: "",
        third: "",
        fourth: "",
        fifth: "",
        sixth: ""
      },
      contractSigned: false,
      loading: false
    };
    this.onInput = this.onInput.bind(this);
    this.onPercentageInput = this.onPercentageInput.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  onInput({ target }) {
    this.setState({ [target.name]: target.value });
  }

  onPercentageInput({ target }) {
    this.setState({
      percentageRanges: {
        ...this.state.percentageRanges,
        [target.name]: target.value
      }
    });
  }

  castAndValidateData(obj) {
    const numbersOnly = ["invested", "earned"];
    let casted = { ...obj };
    Object.keys(obj).forEach(key => {
      let value = obj[key];
      if (numbersOnly.includes(key)) {
        if (typeof value === "string") {
          casted = { ...casted, [key]: parseInt(value) };
        }
      }
    });
    return casted;
  }

  async submitData(e) {
    e.preventDefault();

    const data = this.castAndValidateData(this.state);

    const addInvestor = firebase.functions().httpsCallable("addInvestor");
    try {
      this.setState({ loading: true });
      await addInvestor(data);
      this.setState({
        name: "",
        email: "",
        password: "",
        invested: "",
        earned: "",
        percentageRanges: {
          first: "",
          second: "",
          third: "",
          fourth: "",
          fifth: "",
          sixth: ""
        },
        contractSigned: false
      });
      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      name,
      email,
      password,
      invested,
      earned,
      percentageRanges,
      contractSigned
    } = this.state;

    const { first, second, third, fourth, fifth, sixth } = percentageRanges;
    return (
      <Flex column style={{ color: "white" }}>
        <Title>Add Investor</Title>
        <Flex>
          <form onSubmit={this.submitData}>
            <Flex wrap itemsCenter>
              <Flex column itemsStretch>
                <Title as="h3">Info</Title>
                <Input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={name}
                  required
                  onChange={this.onInput}
                />
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={email}
                  required
                  onChange={this.onInput}
                />
                <Input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  required
                  onChange={this.onInput}
                />
                <Input
                  type="number"
                  placeholder="Invested"
                  name="invested"
                  value={invested}
                  required
                  onChange={this.onInput}
                />
                <Input
                  type="number"
                  placeholder="Earned"
                  name="earned"
                  value={earned}
                  required
                  onChange={this.onInput}
                />
                <Checkbox
                  value={contractSigned}
                  required
                  onChange={() =>
                    this.setState({ contractSigned: !contractSigned })
                  }
                />
              </Flex>
              <Flex column>
                <Title as="h3">Percentages</Title>
                <Input
                  type="number"
                  placeholder="250k - 500k"
                  name="first"
                  value={first}
                  required
                  onChange={this.onPercentageInput}
                />
                <Input
                  type="number"
                  placeholder="500k - 1.5M"
                  name="second"
                  value={second}
                  required
                  onChange={this.onPercentageInput}
                />
                <Input
                  type="number"
                  placeholder="1.5M - 5M"
                  name="third"
                  value={third}
                  required
                  onChange={this.onPercentageInput}
                />
                <Input
                  type="number"
                  placeholder="5M - 100M"
                  name="fourth"
                  value={fourth}
                  required
                  onChange={this.onPercentageInput}
                />
                <Input
                  type="number"
                  placeholder="100M - 500M"
                  name="fifth"
                  value={fifth}
                  required
                  onChange={this.onPercentageInput}
                />
                <Input
                  type="number"
                  placeholder="500M+"
                  name="sixth"
                  value={sixth}
                  required
                  onChange={this.onPercentageInput}
                />
              </Flex>
            </Flex>
            <Button green={!this.state.loading} muted={this.state.loading}>
              {this.state.loading ? "Loading" : "Save"}
            </Button>
          </form>
        </Flex>
      </Flex>
    );
  }
}
