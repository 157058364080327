import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Login, Admin, Investors } from "./pages";

const RedirectToLogin = () => <Redirect to="/login" />;

const Router = ({ children }) => {
  return (
    <div>
      <BrowserRouter>
        <>
          {children}
          <Switch>
            <Route path="/" exact component={RedirectToLogin} />
            <Route path="/investors" component={Investors} />
            <Route path="/login" component={Login} />
            <Route path="/admin" component={Admin} />
          </Switch>
        </>
      </BrowserRouter>
    </div>
  );
};

export default Router;
