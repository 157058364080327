import { createGlobalStyle } from "styled-components";

const theme = {
  colors: {
    green: "#98be62",
    orange: "#FD8A6B",
    blue: "#049AE5",
    blueLighter: "#89b7ff",
    grey: "#CCCCCC",
    greyLighter: "#F5F6F8",
    greyDarker: "#696969",
    black: "#4a4a4a"
  }
};

const GlobalStyle = createGlobalStyle`

  body {
    font-family: "Gordita";
    background-color: ${theme.colors.greyLighter};
    overflow-x: hidden !important;
  }

  .animated-page-wrapper {
   position: absolute;
    top: 62px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  strong {
    font-family: GorditaMedium;
  }
`;

export { GlobalStyle, theme };
