import React, { Component } from "react";
import { Grid, Flex } from "../ui";
import { InvestorCard } from "../components";
import { firebase } from "../firebase";

export default class ManageInvestors extends Component {
  constructor() {
    super();
    this.state = {
      investors: []
    };
    this.fetchData = this.fetchData.bind(this);
    this.deleteInvestor = this.deleteInvestor.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let { docs } = await firebase
      .firestore()
      .collection("investors")
      .get();
    docs = docs.map(doc => ({ id: doc.id, ...doc.data() }));
    this.setState({ investors: docs });
  }

  async deleteInvestor(id) {
    await firebase
      .firestore()
      .collection("investors")
      .doc(id)
      .delete();
    this.setState({ investors: this.state.investors.filter(i => i.id !== id) });
  }

  render() {
    return (
      <Flex contentCenter={window.innerWidth < 576}>
        <Grid
          gutter={20}
          cols={3}
          items={this.state.investors}
          render={investor => (
            <InvestorCard
              investor={investor}
              onDelete={this.deleteInvestor}
              afterSubmit={this.fetchData}
            />
          )}
        />
      </Flex>
    );
  }
}
