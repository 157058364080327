import React, { Component } from "react";
import Router from "./Router";
// import { firebase } from "./firebase";

class App extends Component {
  // constructor() {
  //   super();
  //   this.state = {
  //     authListener: null
  //   };
  // }

  // async componentDidMount() {
  //   const authListener = firebase.auth().onAuthStateChanged(user => {
  //     if (user) {
  //       firebase
  //         .auth()
  //         .currentUser.getIdTokenResult(true)
  //         .then(data => {
  //           // console.log(data.claims);
  //         });
  //     } else {
  //     }
  //     this.setState({ authListener });
  //   });
  // }

  // async componentWillUnmount() {
  //   this.setState({ authListener: null });
  // }

  render() {
    return <Router />;
  }
}

export default App;
