import React from "react";
import styled from "styled-components";
import InlineSVG from "svg-inline-react";
import { theme } from "../theme";

const InputContainer = styled.div`
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0.5rem;
  input {
    width: 100%;
  }
  svg,
  img {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    width: 2rem;
    transform: translateY(-50%);
  }
`;

const InputBase = styled.input`
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  outline: none;
  border: none !important;
  background-color: white;
  max-width: 100%;
  color: ${theme.colors.greyDarker};
  padding: 1.3rem 4rem 1.3rem 1.3rem;
  box-sizing: border-box;
  margin: 0.5rem;
  @media screen and (min-width: 992px) {
    width: 500px;
  }
  @media screen and (max-width: 576px) {
    max-width: 100%;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const Input = props => {
  if (props.icon) {
    return (
      <InputContainer>
        <InputBase {...props} />
        {props.svg ? (
          <InlineSVG src={props.icon} />
        ) : (
          <img src={props.icon} alt="icon" />
        )}
      </InputContainer>
    );
  } else {
    return <InputBase {...props} />;
  }
};

export default Input;
