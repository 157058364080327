import React from "react";
import styled from "styled-components";

import {theme} from "../theme";

const SelectContainer = styled.div`
  padding: 0.4rem 0 0.9rem 4px;
    @media screen and (max-width: 1400px) {
        padding-bottom: 0.7rem;
    }
    @media screen and (max-width: 1300px) {
        padding-bottom: 0.5rem;
    }
    @media screen and (max-width: 1024px) {
    padding-left: 20px;
    //padding-top: 0.9rem;
  }
`;
const SelectBase = styled.select`
   
    transform: translateX(-2px);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 8px;
    outline: none;
    background-color: transparent;
    border: none;
    color: #f9f9f9;
    font-family: GorditaMedium;
    font-size: 35px;
    box-sizing: border-box;
    margin: 0.5rem 0.5rem 0.5rem 0;
     
     width: 100%;
    @media screen and (max-width: 992px) {
    max-width: 100%;
    width: 100%;
  }

    
  @media screen and (max-width: 1300px) {
        font-size: 25px;
      
    }
    
   @media screen and (max-width: 768px) {
    max-width: 100%;

  }
  @media screen and (max-width: 576px) {
    max-width: 250px;
     font-size: 1.5em;
     
  }
 
`;

const SelectOption = styled.option`
  font-size: 18px;
  color: #b3b6b5;
`;

const Select = props =>
    <SelectContainer>
        <SelectBase {...props}>
            {
                props.options.map(o => <SelectOption key={o.value} value={o.value}>{o.title}</SelectOption>)
            }
        </SelectBase>
    </SelectContainer>;
export default Select;
