import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import { Button, Input, Flex, Title, Checkbox, Modal } from "../ui";
import { firebase } from "../firebase";
import accounting from "accounting";

const InvestorCardContainer = styled.div`
  box-sizing: border-box;
  background-color: white;
  color: ${theme.colors.greyDarker};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 1rem 2rem;
  border-bottom: 4px solid ${theme.colors.blueLighter};
`;

const InvestorCardTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 0.75rem;
  color: ${({ signed }) =>
    signed ? theme.colors.green : theme.colors.greyDarker};
`;

const InvestorAmount = styled.p`
  color: ${theme.colors.grey};
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 0.5rem;
`;

const InvestorDetails = styled(InvestorAmount)`
  font-size: 1rem;
`;

function concatName(name) {
  return name
    .split(" ")
    .map(word => word.charAt(0) + ".")
    .join(" ");
}

function formatMoney(number) {
  if (number > 0) {
    return accounting.formatMoney(number, "€ ", 0, ".");
  } else {
    return 0;
  }
}

class AddInvestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: undefined,
      email: undefined,
      password: undefined,
      invested: undefined,
      earned: undefined,
      percentageRanges: {
        first: undefined,
        second: undefined,
        third: undefined,
        fourth: undefined,
        fifth: undefined,
        sixth: undefined
      },
      contractSigned: undefined,
      loading: undefined
    };
    this.onInput = this.onInput.bind(this);
    this.onPercentageInput = this.onPercentageInput.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  onInput({ target }) {
    this.setState({ [target.name]: target.value });
  }

  onPercentageInput({ target }) {
    this.setState({
      percentageRanges: {
        ...this.state.percentageRanges,
        [target.name]: target.value
      }
    });
  }

  castAndValidateData(obj) {
    const numbersOnly = ["invested", "earned"];
    let casted = { ...obj };
    Object.keys(obj).forEach(key => {
      let value = obj[key];
      if (numbersOnly.includes(key)) {
        if (typeof value === "string") {
          casted = { ...casted, [key]: parseInt(value) };
        }
      }
    });
    return casted;
  }

  async submitData(e) {
    e.preventDefault();

    let data = JSON.parse(JSON.stringify(this.state));
    data = this.castAndValidateData(data);

    const updateInvestor = firebase.functions().httpsCallable("updateInvestor");
    try {
      this.setState({ loading: true });
      await updateInvestor({ uid: this.props.investor.id, payload: data });
      this.setState({
        name: undefined,
        email: undefined,
        password: undefined,
        invested: undefined,
        earned: undefined,
        percentageRanges: {
          first: undefined,
          second: undefined,
          third: undefined,
          fourth: undefined,
          fifth: undefined,
          sixth: undefined
        },
        contractSigned: undefined
      });
      this.props.afterSubmit();
      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      name,
      email,
      password,
      invested,
      earned,
      percentageRanges,
      contractSigned
    } = this.state;

    const { first, second, third, fourth, fifth, sixth } = percentageRanges;
    return (
      <form onSubmit={this.submitData}>
        <Flex wrap itemsCenter>
          <Flex column itemsStretch>
            <Title as="h3">Info</Title>
            <Input
              type="text"
              placeholder={this.props.investor.name}
              name="name"
              value={name}
              autocomplete="off"
              onChange={this.onInput}
            />
            <Input
              type="email"
              placeholder={this.props.investor.email}
              name="email"
              value={email}
              autocomplete="off"
              onChange={this.onInput}
            />
            <Input
              type="password"
              placeholder={this.props.investor.password}
              name="password"
              value={password}
              autocomplete="off"
              onChange={this.onInput}
            />
            <Input
              type="number"
              placeholder={this.props.investor.invested}
              name="invested"
              value={invested}
              autocomplete="off"
              onChange={this.onInput}
            />
            <Input
              type="number"
              placeholder={this.props.investor.earned}
              name="earned"
              value={earned}
              autocomplete="off"
              onChange={this.onInput}
            />
            <Checkbox
              value={contractSigned}
              autocomplete="off"
              onChange={() =>
                this.setState({ contractSigned: !contractSigned })
              }
            />
          </Flex>
          <Flex column>
            <Title as="h3">Percentages</Title>
            <Input
              type="number"
              placeholder="250k - 500k"
              name="first"
              value={first}
              required
              autocomplete="off"
              onChange={this.onPercentageInput}
            />
            <Input
              type="number"
              placeholder="500k - 1.5M"
              name="second"
              value={second}
              required
              autocomplete="off"
              onChange={this.onPercentageInput}
            />
            <Input
              type="number"
              placeholder="1.5M - 5M"
              name="third"
              value={third}
              required
              autocomplete="off"
              onChange={this.onPercentageInput}
            />
            <Input
              type="number"
              placeholder="5M - 100M"
              name="fourth"
              value={fourth}
              required
              autocomplete="off"
              onChange={this.onPercentageInput}
            />
            <Input
              type="number"
              placeholder="100M - 500M"
              name="fifth"
              value={fifth}
              required
              autocomplete="off"
              onChange={this.onPercentageInput}
            />
            <Input
              type="number"
              placeholder="500M+"
              name="sixth"
              value={sixth}
              required
              autocomplete="off"
              onChange={this.onPercentageInput}
            />
          </Flex>
        </Flex>
        <Button green={!this.state.loading} muted={this.state.loading}>
          {this.state.loading ? "Loading" : "Save"}
        </Button>
        <Button
          orange
          onClick={e => {
            e.preventDefault();
            this.props.onCancel();
          }}
        >
          Cancel
        </Button>
      </form>
    );
  }
}

class InvestorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    };
  }
  render() {
    const { investor, readonly, onDelete } = this.props;
    if (readonly) {
      return (
        <InvestorCardContainer>
          <InvestorCardTitle>{concatName(investor.name)}</InvestorCardTitle>
          <InvestorAmount>{formatMoney(investor.invested)}</InvestorAmount>
        </InvestorCardContainer>
      );
    } else {
      return (
        <>
          <Modal
            render={
              <AddInvestor
                investor={investor}
                onCancel={() => this.setState({ modalVisible: false })}
                afterSubmit={this.props.afterSubmit}
              />
            }
            visible={this.state.modalVisible}
          />
          <InvestorCardContainer>
            <InvestorCardTitle signed={investor.contractSigned}>
              {investor.name}
              {investor.contractSigned ? " ✔" : null}
            </InvestorCardTitle>
            <InvestorAmount>
              Invested - €{formatMoney(investor.invested)}
            </InvestorAmount>
            {/* <InvestorDetails>Earned - €{investor.earned}</InvestorDetails> */}
            <InvestorDetails>{investor.email}</InvestorDetails>
            <Button orange fullWidth onClick={() => onDelete(investor.id)}>
              Delete
            </Button>
            <Button
              blue
              fullWidth
              onClick={() => this.setState({ modalVisible: true })}
            >
              Edit
            </Button>
          </InvestorCardContainer>
        </>
      );
    }
  }
}

export default InvestorCard;
