import React, {Component} from "react";
import {Flex, Input, Select, Card, Button} from "../ui";
import styled from "styled-components";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {firebase} from "../firebase";
import "./Slider.css";
import accounting from "accounting";

import {theme} from "../theme";

const Text = styled.p`
  color: ${theme.colors.greyDarker};
  font-family: GorditaRegular;
`;

const Title = styled.h1`
  font-size: 1.8em;
  font-weight: 400;
  font-size: ${({size}) => size + "rem"};
  font-family: GorditaMedium;
  color: #707070;

  @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
    margin-bottom: 0;

    & > div {
      width: auto;
      font-size: 1rem;
    }

    button {
      margin-top: 1rem;
    }
  }
`;

const SmallTitle = styled(Title)`
  font-size: 22px;

  font-family: "GorditaMedium";
  & > span {
    color: #f9f9f9 !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.2em;
  }

  @media screen and (max-width: 1300px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1300px) {
    font-size: 1.3rem;
  }
`;

const DisplayInput = styled(Input)`
  font-size: 35px;
  padding: 0.3em;
  padding-top: 0.6em;
  width: 250px;
  resize: horizontal;
  background: white;
  border-radius: 5px;
  margin-left: -1px;
  font-weight: 500;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  box-sizing: border-box;
  font-family: "GorditaMedium" !important;
  padding-left: 40px;

  ${({flat}) =>
    flat
        ? `
    color: white;
    box-shadow: none;
    background: none;
    font-family: 'GorditaMedium';
  `
        : null}
  @media screen and (max-width: 576px) {
    font-size: 1.5em;
    max-width: 250px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 30px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 25px;
    padding-left: 27px;
  }
`;

const ContentCard = styled(Card)`
  margin-right: 50px;
  margin-top: 0px;

  .text {
    min-height: 140px;
    @media screen and (max-width: 1900px) {
      min-height: 160px;
    }
    @media screen and (max-width: 1500px) {
      min-height: 194px;
    }
    @media screen and (max-width: 1280px) {
      min-height: 200px;
    }
    @media screen and (max-width: 1200px) {
      min-height: 230px;
    }
    @media screen and (max-width: 1024px) {
      min-height: auto;
    }
    margin-top: 2rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    margin-top: 50px;
    width: 85%;
  }

  @media screen and (max-width: 1024px) {
    min-height: 160px;
  }
`;

const ContainerCard = styled(ContentCard)`
  margin-right: 50px;
  margin-top: 0;
  background-color: transparent;
  box-shadow: none;
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    margin-top: 50px;
    width: 85%;
  }
`;

const FlexBoxInputs = styled(Flex)`
  & > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 30%;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    justify-content: stretch;

    & > div {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 1024px) {
    & > div {
      width: auto;
      font-size: 1rem;
    }

    button {
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 1300px) {
    button {
      width: 100% !important;
      font-size: 13px !important;
      padding: 1rem !important;
    }
  }

  button {
    font-size: 17px;
    margin-left: 0;
    margin-right: 0;
    padding: 1em 0;
    white-space: nowrap;
    text-align: center;
    width: 80%;
  }
`;

const FlexBoxRange = styled(Flex)`
  & > div {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 30%;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    justify-content: stretch;
  }

  @media screen and (max-width: 1024px) {
    & > div {
      width: 100%;
    }
  }
`;
const MIN_SLIDER_VALUE = 250000;
const MIN_INVESTED = 1000;
export default class Account extends Component {
    constructor() {
        super();

        this.state = {
            contractSigned: false,
            invested: 0,
            load: false,
            constractSent: false,
            contractLoading: false,
            percent: 0,
            financing: 0,
            profit: 0,
            exit: 0,
            financingPercent: 0,
            profitPercent: 0,
            exitPercent: 0,
            percentageRanges: null,
            potentialEarnings: 0,
            slider: {
                min: 0,
                max: 50 * 1000 * 1000,
                step: 50 * 100
            },
            select: [],
            investedButNotSigned: false
        };
        this.calcPercent = this.calcPercent.bind(this);
        this.calcPercentageRanges = this.calcPercentageRanges.bind(this);
        this.sendMail = this.sendMail.bind(this);
    }

    async componentDidMount() {
        try {
            const uid = window.localStorage.getItem("uid");
            if (!uid) {
                const currentUser = await firebase.auth().currentUser;
                window.localStorage.setItem("uid", currentUser.uid);
                window.localStorage.setItem("displayName", currentUser.displayName);
                window.localStorage.setItem("email", currentUser.email);
            }
            const doc = await firebase
                .firestore()
                .collection("investors")
                .doc(window.localStorage.getItem("uid"))
                .get();
            this.setState(
                {
                    contractSigned: doc.data().contractSigned,
                    invested: doc.data().contractSigned || doc.data().invested ?
                        doc.data().invested : MIN_INVESTED,
                    investedButNotSigned: !doc.data().contractSigned && doc.data().invested,
                    percentageRanges: doc.data().percentageRanges,
                    load: true
                },
                () => {
                    if (!doc.data().contractSigned && !doc.data().invested) {
                        this.calcPercentageRanges();
                    }

                    let potentialEarnings =
                        (this.state.financingPercent / 100) * this.state.financing +
                        (this.state.profitPercent / 100) * this.state.profit +
                        (this.state.exitPercent / 100) * this.state.exit;

                    this.setState({
                        potentialEarnings
                    });
                }
            );
        } catch (error) {
            console.error(error);
        }
    }

    calcPercentageRanges() {
        let multiplier = this.state.invested / 10000;
        let percentageRanges = {
            first: parseFloat(3.5 * multiplier).toFixed(2),
            second: parseFloat(2 * multiplier).toFixed(2),
            third: parseFloat(1.5 * multiplier).toFixed(2),
            fourth: parseFloat(1 * multiplier).toFixed(2),
            fifth: parseFloat(0.7 * multiplier).toFixed(2),
            sixth: parseFloat(0.4 * multiplier).toFixed(2)
        };
        this.setState({
            percentageRanges
        });
    }

    calcPercent(val, key) {
        let percent;
        if (0 === val) {
            percent = 0;
        }
        if (val > 0 && val < MIN_SLIDER_VALUE) {
            val = MIN_SLIDER_VALUE;
        }
        if (0 < val && val < 500000) {
            percent = this.state.percentageRanges.first;
        }
        if (500000 <= val && val < 1500000) {
            percent = this.state.percentageRanges.second;
        }
        if (1500000 <= val && val < 5000000) {
            percent = this.state.percentageRanges.third;
        }
        if (5000000 <= val && val < 100000000) {
            percent = this.state.percentageRanges.fourth;
        }
        if (100000000 <= val && val < 500000000) {
            percent = this.state.percentageRanges.fifth;
        }
        if (val >= 500000000) {
            percent = this.state.percentageRanges.sixth;
        }
        this.setState(
            {
                [key]: val,
                [key + "Percent"]: percent
            },
            () => {
                let potentialEarnings =
                    (this.state.financingPercent / 100) * this.state.financing +
                    (this.state.profitPercent / 100) * this.state.profit +
                    (this.state.exitPercent / 100) * this.state.exit;
                let max = this.state.invested * 1000;
                //this if reset a target field when one of sliders is set to 0
                // if (val === 0) {
                //   potentialEarnings = 0;
                // }
                if (
                    !this.state.financingPercent &&
                    !this.state.profitPercent &&
                    !this.state.exitPercent
                ) {
                    potentialEarnings = 0;
                }
                if (potentialEarnings <= max && potentialEarnings !== 10000) {
                    this.setState({
                        potentialEarnings
                    });
                } else if (potentialEarnings === 10000) {
                    this.setState({
                        potentialEarnings: 0
                    });
                } else {
                    this.setState({
                        potentialEarnings: max
                    });
                }
            }
        );
    }

    getSelectValues = () => {
        let selectValues = [];
        for (let i = 1000; i <= 25000; i += 1000) {
            selectValues.push({
                value: i,
                title: this.formatMoney(i)
            });
        }
        return selectValues;
    };

    async sendMail() {
        const name = await firebase.auth().currentUser.displayName;
        const email = await firebase.auth().currentUser.email;
        const invested = this.state.invested;
        const sendContract = firebase.functions().httpsCallable("sendContract");
        try {
            this.setState({contractLoading: true});
            await sendContract({name, email, invested});

            this.setState({contractLoading: false});

            this.setState({constractSent: true});
        } catch (error) {
            console.error(error);
        }
    }

    formatMoney(number) {
        if (number > 0) {
            return accounting.formatMoney(number, "€ ", 0, ".");
        } else {
            return "€ 0";
        }
    }

    render() {
        if (!this.state.load) {
            return null;
        }

        return (
            <>
                <ContainerCard>
                    <FlexBoxInputs wrap contentBetween>
                        <div>
                            <SmallTitle>
                                {this.state.contractSigned ? (
                                    <span>
                    Within the terms of our contract you invested the following
                    amount:
                  </span>
                                ) : (
                                    <span>
                    Set your investment amount to find out your theoretical
                    earnings:
                  </span>
                                )}
                            </SmallTitle>
                            {!this.state.contractSigned ? (
                                <Select
                                    disabled={this.state.investedButNotSigned}
                                    value={this.state.invested}
                                    options={this.getSelectValues()}
                                    onChange={e => {
                                        this.setState(
                                            {
                                                invested: parseInt(e.target.value),
                                                percent: 0,
                                                financing: 0,
                                                profit: 0,
                                                exit: 0,
                                                financingPercent: 0,
                                                profitPercent: 0,
                                                exitPercent: 0,
                                                potentialEarnings: 0
                                            },
                                            () => {
                                                this.calcPercentageRanges();
                                            }
                                        );
                                    }}
                                />
                            ) : (
                                <div className={"euro"}>
                                    <DisplayInput
                                        type="number"
                                        max="25000"
                                        min="0"
                                        readOnly={this.state.contractSigned}
                                        value={this.state.invested}
                                        onChange={e => {
                                            // let numb = e.target.value.match(/\d/g);

                                            let {value} = e.target;
                                            const limit = 25000;
                                            console.log(value);
                                            if (value !== "") {
                                                value = value > limit ? 25000 : value;

                                                this.setState({invested: parseInt(value)}, () => {
                                                    this.calcPercentageRanges();
                                                });
                                            } else {
                                                this.setState({invested: 0});
                                            }
                                        }}
                                        size={1}
                                        flat
                                    />
                                </div>
                            )}
                        </div>
                        <div>
                            <SmallTitle>
                                {this.state.contractSigned ? (
                                    <span>In case of success events you would earn:</span>
                                ) : (
                                    <span>
                    Potential earnings that will be transfered to your bank
                    acount:
                  </span>
                                )}
                            </SmallTitle>
                            <DisplayInput
                                readOnly
                                value={`${this.formatMoney(this.state.potentialEarnings)}`}
                                size={1}
                            />
                        </div>
                        <div>
                            <div/>
                            {!this.state.contractSigned ? (
                                <Button
                                    blue={!this.state.constractSent}
                                    muted={this.state.constractSent}
                                    green={this.state.contractLoading}
                                    disabled={
                                        this.state.constractSent || this.state.contractLoading
                                    }
                                    onClick={this.sendMail}
                                    style={{
                                        width: "250px",
                                        color: "white"
                                    }}
                                >
                                    {this.state.constractSent
                                        ? "Request received"
                                        : "Request contract"}
                                </Button>
                            ) : null}
                        </div>
                    </FlexBoxInputs>
                </ContainerCard>

                <ContentCard>
                    <Title size={window.innerWidth < 768 ? 1.25 : 2}>
                        Here you can simulate the company's future success events.{" "}
                    </Title>
                    <FlexBoxRange wrap={window.innerWidth < 1450} contentBetween>
                        <div>
                            <div className="text">
                                <Text>
                  <span style={{fontFamily: "GorditaMedium"}}>
                    You get {this.state.financingPercent}% of that amount with
                    each upcoming Financing round
                  </span>
                                </Text>
                                <Text>
                                    <strong>"Financing round" </strong>
                                    means the cash capital increase coming from new funding by new
                                    shareholders, investors and/or lenders.
                                </Text>
                            </div>
                            <Title size={1.75} style={{fontWeight: 500}}>
                                {this.formatMoney(this.state.financing)}
                            </Title>
                            <Slider
                                min={this.state.slider.min}
                                max={this.state.slider.max}
                                step={this.state.slider.step}
                                value={this.state.financing}
                                onChange={financing => {
                                    this.calcPercent(financing, "financing");
                                }}
                            />
                        </div>
                        <div>
                            <div className="text">
                                <Text>
                  <span style={{fontFamily: "GorditaMedium"}}>
                    You get {this.state.profitPercent}% of that amount with each
                    upcoming Profit distribution
                  </span>
                                </Text>
                                <Text>
                                    <strong>"Profit distribution" </strong>
                                    means any distribution of profit decided, executed,
                                    distributed by and to Albrecht Panknin
                                </Text>
                            </div>
                            <Title size={1.75} style={{fontWeight: 500}}>
                                {this.formatMoney(this.state.profit)}
                            </Title>
                            <Slider
                                value={this.state.profit}
                                min={this.state.slider.min}
                                max={this.state.slider.max}
                                step={this.state.slider.step}
                                onChange={profit => this.calcPercent(profit, "profit")}
                            />
                        </div>
                        <div>
                            <div className="text">
                                <Text>
                  <span style={{fontFamily: "GorditaMedium"}}>
                    You get {this.state.exitPercent}% of that amount with each
                    upcoming Exit
                  </span>
                                </Text>
                                <Text>
                                    <strong>"Exit" </strong>
                                    means the sale of shares (in whole or in part) to Albrecht
                                    Panknin in the course of which he receives proceeds of sale in
                                    cash.
                                </Text>
                            </div>
                            <Title size={1.75} style={{fontWeight: 500}}>
                                {this.formatMoney(this.state.exit)}
                            </Title>
                            <Slider
                                value={this.state.exit}
                                min={this.state.slider.min}
                                max={this.state.slider.max}
                                step={this.state.slider.step}
                                onChange={exit => this.calcPercent(exit, "exit")}
                            />
                        </div>
                    </FlexBoxRange>
                </ContentCard>
            </>
        );
    }
}
